import Head from "next/head";

type HrefLangProps = {
  defaultURL: string;
  hrefLangs: string[] | false;
};

/**
 * @description
 * marketing.json ->
 * if (hreflang not needed all languages) -> add hrefLangs : false,
 * if (hrefLang need for a specific language/s) add hrefLangs : ["es"]
 */
const HrefLang = ({ defaultURL, hrefLangs }: HrefLangProps) => {
  const currentUrl = new URL(defaultURL);
  const domainName = "https://www.setmore.com";
  const regex = /^\/(en|pt|es|de|fr|it)(?=(\/|$))/;
  const pathName = currentUrl.pathname.replace(regex, "");
  const isRootPath = pathName === "/";

  return (
    <>
      <Head>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${domainName}${pathName}`}
        />
        {hrefLangs !== false &&
          hrefLangs.map((lang) => (
            <link
              rel="alternate"
              hrefLang={lang}
              href={`${domainName}/${lang}${isRootPath ? "" : pathName}`}
              key={`${domainName}/${lang}${isRootPath ? "" : pathName}`}
            />
          ))}
      </Head>
    </>
  );
};

export default HrefLang;
