import dynamic from "next/dynamic";
import Header from "./organisms/common/Header";
import Footer from "./organisms/common/Footer";
import SEO from "./organisms/common/SEO";
import { useState, useEffect } from "react";
import { setLocalStorageItem, gsiScript } from "../util/utils";
const GsiOnetapWidget = dynamic(() =>
  import("@components/organisms/common/GsiOnetapWidget")
);

export default function Theme({ header, footer, seo, className, children }) {
  const [userDeviceInfo, setUserDeviceInfo] = useState("desktop");
  const DetectDevice = () => {
    const userAgent =
      typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
    if (userAgent.includes("Android")) {
      setUserDeviceInfo("android");
      return;
    }
    if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      setUserDeviceInfo("ios");
      return;
    }
  };
  // Remove this variable when the 2.0 signup is migrated 100%
  const pagesWithoutGSI = ["/industries/photography", "/home", "/home-new"];

  let data = {};
  if (typeof window !== "undefined") {
    let currentUrl = new URL(window.location.href);
    data.pathname = currentUrl.pathname;
  }
  // Add Class Name After Device Detection
  className = className.concat(` device--${userDeviceInfo}`);
  useEffect(() => {
    DetectDevice();
    setLocalStorageItem("url", window.location.href);
    gsiScript();
  }, []);
  return (
    <>
      <SEO data={seo} />
      <main className={className}>
        <Header data={header} pathname={data.pathname} />
        {!pagesWithoutGSI.includes(data.pathname) && <GsiOnetapWidget />}
        {children}
        <Footer data={footer} />
      </main>
    </>
  );
}
