import Head from "next/head";
import HrefLang from "../../atoms/document/HrefLang";

export default function SEO({ data }) {
  return (
    <>
      <Head>
        {/* Primary Meta Tags  */}
        {data.hasOwnProperty("indexing") && data.indexing === false && (
          <meta
            name="robots"
            content="noindex, nofollow"
            data-meta-from="SEO.js"
          />
        )}
        <title>{data.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="yandex-verification" content="cff3a4bed1cce307" />
        <meta
          name="facebook-domain-verification"
          content="csn7q0gk0mng9tv2zfzq7vndzw4i5l"
        />
        <meta name="title" content={data.meta_title} />
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.keywords} />
        {/* Open Graph / Facebook  */}
        <meta property="og:title" content={data.og.title} />
        <meta property="og:description" content={data.og.description} />
        <meta property="og:url" content={data.og.url} />
        <meta property="og:image" content={data.og.image} />
        <meta property="og:type" content="website" />
        {/* Twitter  */}
        <meta property="twitter:title" content={data.twitter.title} />
        <meta
          property="twitter:description"
          content={data.twitter.description}
        />
        <meta property="twitter:url" content={data.twitter.url} />
        <meta property="twitter:image" content={data.twitter.image} />
        <meta name="twitter:image:alt" content={data.twitter.image_alt} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@setmore" />
        <meta name="twitter:creator" content="@setmore" />
        <link rel="canonical" href={data.og.url} />
      </Head>
      <HrefLang
        defaultURL={data.og.url}
        hrefLangs={
          data.hasOwnProperty("hrefLangs")
            ? data.hrefLangs
            : ["es", "pt", "de", "fr", "it"]
        }
      />
    </>
  );
}
