import React, { MouseEvent, useState } from "react";
import { CONSTANTS } from "@util/utils";

type VideoProps = {
  data: {
    id: string;
    poster: string;
    src: string;
  };
  toggleVideo: (id: string) => void;
  classNames?: string;
};

const Video = ({ data, classNames }: VideoProps) => {
  const [videoId, setVideoId] = useState("");
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const id = e.currentTarget.getAttribute("data-id");
    if (id) {
      setVideoId(id);
    }
  };

  return (
    <>
      <div className={`video-highlight ${classNames}`}>
        <a
          className="video-timg"
          id="videoTimgOne"
          onClick={handleClick}
          data-id={data.id}
        >
          <video
            autoPlay
            muted
            loop
            poster={data.poster}
            preload="none"
            data-id={data.id}
            className="highlight-video md:w-100"
            playsInline
          >
            <source src={data.src} type="video/mp4" />
          </video>
          <button
            id="playBtnOne"
            className="play-btn"
            aria-label="Play Video Button"
            data-id={data.id}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80 80"
              width={80}
              height={80}
              aria-hidden="true"
            >
              <g fill="none">
                <circle
                  cx={40}
                  cy={40}
                  r={38}
                  fill="#000"
                  fillOpacity=".25"
                  stroke="#FFF"
                  strokeWidth={4}
                />
                <path
                  fill="#FFF"
                  d="M32 55.73c0 1.696 1.978 2.622 3.28 1.536l18.876-15.73c.96-.8.96-2.273 0-3.072L35.28 22.734c-1.302-1.086-3.28-.16-3.28 1.536v31.46z"
                />
              </g>
            </svg>
          </button>
        </a>
      </div>
      {videoId && (
        <div
          className="testimonial-modal"
          onClick={() => {
            setVideoId("");
          }}
          id="testimonial-modal"
        >
          <div className="video-content">
            <div className="video-cont is-played">
              <iframe
                id="videoUtube"
                frameBorder="0"
                allowFullScreen={true}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                width="960"
                height="539"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&amp;controls=1&amp;showinfo=0&amp;modestbranding=1&amp;loop=1&amp;fs=0&amp;cc_load_policy=0&amp;iv_load_policy=3&amp;autohide=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.setmore.com&amp;widgetid=2`}
                data-gtm-yt-inspected-1_19="true"
              ></iframe>
            </div>
            <div className="text-center">
              <a
                href={
                  process.env.APP_MODE === "live"
                    ? `${CONSTANTS.live.universalSignupUrl}/start-now`
                    : `${CONSTANTS.staging.universalSignupUrl}/start-now`
                }
                target="_blank"
                className="btn-primary lg:mt-5 md:mt-2.5"
                id="button-hero-cta"
              >
                <span>Get started</span>
              </a>
            </div>
          </div>
          <button id="closeBtn">
            Close
            <svg width="24" height="24" className="ml-1">
              <use href="/sprite.svg#modal-close"></use>
            </svg>
          </button>
          <style jsx>{`
            .testimonial-modal {
              width: 100%;
              display: block;
            }
          `}</style>
        </div>
      )}
    </>
  );
};

export default Video;
