import React from "react";
import Head from "next/head";

type AdaptiveCriticalImageProps = {
  dpi: string;
  mobile: string;
  tablet?: string;
  className?: string;
  dimensions: [number, number];
} & React.ImgHTMLAttributes<HTMLImageElement>;
const AdaptiveCriticalImage = ({
  dpi,
  mobile,
  tablet,
  className,
  src,
  alt,
  dimensions,
}: AdaptiveCriticalImageProps) => {
  const PreLoad = () => {
    return (
      <Head>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          imageSrcSet={`${src}-${mobile}.webp, ${src}-${mobile}${dpi}.webp 2x`}
          media={`(max-width:${tablet ? "640px" : "992px"})`}
          type="image/webp"
        />
        {tablet && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            imageSrcSet={`${src}-${tablet}.webp, ${src}-${tablet}${dpi}.webp 2x`}
            media="(min-width:641px) and (max-width:992px)"
            type="image/webp"
          />
        )}
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          imageSrcSet={`${src}.webp, ${src}${dpi}.webp 2x`}
          media="(min-width:993px)"
          type="image/webp"
        />
      </Head>
    );
  };

  const AdaptiveSources = () => {
    return (
      <>
        <source
          srcSet={`${src}-${mobile}.webp, ${src}-${mobile}${dpi}.webp 2x`}
          type="image/webp"
          media={`(max-width:${tablet ? "640px" : "992px"})`}
        />
        <source
          srcSet={`${src}-${mobile}.png, ${src}-${mobile}${dpi}.png 2x`}
          type="image/png"
          media={`(max-width:${tablet ? "640px" : "992px"})`}
        />
        {tablet && (
          <>
            <source
              srcSet={`${src}-${tablet}.webp, ${src}-${tablet}${dpi}.webp 2x`}
              type="image/webp"
              media="(min-width:641px) and (max-width:992px)"
            />
            <source
              srcSet={`${src}-${tablet}.png, ${src}-${tablet}${dpi}.png 2x`}
              type="image/png"
              media="(min-width:641px) and (max-width:992px)"
            />
          </>
        )}
        <source
          srcSet={`${src}.webp, ${src}${dpi}.webp 2x`}
          type="image/webp"
          media="(min-width:993px)"
        />
        <source
          srcSet={`${src}.png, ${src}${dpi}.png 2x`}
          type="image/png"
          media="(min-width:993px)"
        />
      </>
    );
  };

  return (
    <>
      <PreLoad />
      <AdaptiveSources />
      {dimensions ? (
        <img
          src={`${src}.png`}
          alt={alt ? alt : "Image needs Alt Text"}
          width={dimensions[0]}
          height={dimensions[1]}
          className={className ? className : ""}
        />
      ) : (
        <img
          src={`${src}.png`}
          alt={alt ? alt : "Image needs Alt Text"}
          className={className ? className : ""}
        />
      )}
    </>
  );
};

export default AdaptiveCriticalImage;
